/* eslint-disable react/jsx-no-target-blank */
import React from "react";

import getConfig from "next/config";
import styles from "./ArticleCard.module.scss";

interface ArticleCardProps {
    eventProps: any;
    contentUrl: string;
    imageUrl: string;
}

const ArticleCard = (props: ArticleCardProps & React.HTMLProps<HTMLAnchorElement>) => {
    const { publicRuntimeConfig: { museHost } } = getConfig();
    const {
        title, imageUrl, eventProps, contentUrl
    } = props;
    const {
        placementOrder,
        linkName,
    } = eventProps;

    const impressionTracking = {
        company_craft_id: null,
        company_id: null,
        company_name: null,
        company_short_name: null,
        content_type: "module",
        placement_position: placementOrder,
        sp_content_is_tile: true,
        sp_impression_button_type: null,
        sp_impression_card_type: null,
        sp_impression_component: "tile",
        sp_impression_insert_type: null,
        sp_impression_modal_type: null,
        sp_impression_position: "main",
        sp_impression_search_id: null,
        sp_impression_tile_type: "article",
        sp_is_sponsored: false,
        sp_page_section: "homepage",
        sp_click_button_text: linkName,
        sp_click_button_type: null,
        sp_click_component: "tile",
        sp_click_component_card_type: null,
        sp_click_component_tile_type: "article",
        sp_click_insert_type: null,
        sp_click_link_text: linkName,
        sp_click_position: placementOrder,
        sp_click_search_id: null,
        sp_click_target_page_section: "advice",
    };

    const height = 250;
    const width = 344;
    return (
        <section
            className={styles.articleCard}
            data-tracking-props={JSON.stringify(impressionTracking)}
            data-tracking-event-type="impression"
        >
            <a
                className={styles.rapper}
                href={`${museHost}${contentUrl}`}
                target="_blank"
                data-tracking-props={JSON.stringify(impressionTracking)}
                data-tracking-event-type="click"
            >
                <section className={styles.imageContainer}>
                    <img
                        alt={title}
                        className={styles.image}
                        height={height}
                        loading="lazy"
                        src={imageUrl}
                        width={width}
                    />
                </section>
                <h3 className={styles.title}>
                    {title}
                </h3>
            </a>
        </section>
    );
};

export { ArticleCard };
export type { ArticleCardProps };
