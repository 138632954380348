import React, { useEffect } from "react";

import { IFeaturedCompanies } from "interfaces/featuredCompanies";
import { SquareButton } from "@themuse/design-system";
import getConfig from "next/config";

import { TrendingSearch } from "components/TrendingSearch";

import { IArticleTile, RecentArticles } from "./RecentArticles";
import { Culture } from "./Culture";
import { ExploreCompanies } from "./ExploreCompanies";
import { CoachContent } from "./CoachContent";
import { HomepageHeader } from "./HomepageHeader";
import { AdviceContent } from "./AdviceContent";
import { CompanyLogos } from "./CompanyLogos";
import { Newsletter } from "./NewsLetter";

import styles from "./ContentWell.module.scss";

const ContentWell = (props: { featuredCompanies: IFeaturedCompanies; recentArticles: IArticleTile[]; }) => {
    const { publicRuntimeConfig: { museHost } } = getConfig();
    const { featuredCompanies, recentArticles } = props;
    const hasMultipleFeaturedCompanies: boolean = featuredCompanies?.featuredCompanies2?.length > 0
        || featuredCompanies?.featuredCompanies3?.length > 0;

    useEffect(() => {
        window.dataLayer.push({
            event: "homepageLoaded",
            page: "/",
            path: "/"
        });
    }, []);

    // We add an SVG off screen so we can reference it by ID as needed.
    return (
        <div className={styles.contentWell}>
            <HomepageHeader />
            {featuredCompanies
                && (
                    <ExploreCompanies {...{
                        featureHeading: featuredCompanies?.featuredCompaniesHeading1,
                        exploreCompaniesSubheading: featuredCompanies?.exploreCompaniesSubheading,
                        companies: featuredCompanies?.featuredCompanies1,
                        heading: featuredCompanies?.exploreCompaniesHeading,
                    }}
                    />
                )}
            <Culture />
            <Newsletter />
            <CompanyLogos />
            {featuredCompanies
                && (
                    <ExploreCompanies {...{
                        featureHeading: featuredCompanies?.featuredCompaniesHeading2,
                        companies: featuredCompanies?.featuredCompanies2,
                    }}
                    />
                )}
            {featuredCompanies
                && (
                    <ExploreCompanies {...{
                        featureHeading: featuredCompanies?.featuredCompaniesHeading3,
                        companies: featuredCompanies?.featuredCompanies3,
                    }}
                    />
                )}
            {hasMultipleFeaturedCompanies && (
                <section className={styles.multipleCompanyButtonSection}>
                    <SquareButton
                        color="secondary-100"
                        className={styles.cta}
                        href={`${museHost}/companies`}
                    >
                        Explore Companies
                    </SquareButton>
                </section>
            )}
            <div className={styles.trendingSearch}>
                <TrendingSearch {...{ type: "trendingSearchBlock" }} />
            </div>
            <AdviceContent />
            <RecentArticles {...{ recentArticles }} />
            <CoachContent />
        </div>
    );
};
ContentWell.displayName = "ContentWell";
export { ContentWell };
