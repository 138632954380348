/* eslint-disable react/no-this-in-sfc */
import React from "react";
import getConfig from "next/config";
import { Text } from "@themuse/design-system";
import { AnimationContainer } from "./AnimationContainer";

import styles from "./Culture.module.scss";

const Culture = () => {
    const { publicRuntimeConfig: { staticAssetHost, pilboxHost } } = getConfig();

    const CMSLeadMediaAssetUrl = (imageName: string) => {
        const imagePath = `/media/lead/${imageName}`;
        return `${staticAssetHost}${imagePath}`;
    };

    const imageUrl = (assetUrl: string, width: number, height: number) => {
        const pillboxFilters = "&filter=antialias&opt=1&pos=center&prog=1&q=keep";
        const pilboxParams = `&url=${assetUrl}${width ? "&w=" : ""}${width || ""}&h=${height}${pillboxFilters}`;
        return `${pilboxHost}?${pilboxParams}`;
    };

    const image1FileName = "homepage_culture_row_1.jpg";
    const image2FileName = "homepage_culture_row_2.jpg";
    const image3FileName = "homepage_culture_row_3.jpg";

    const imageUrls = {
        mobile1: imageUrl(CMSLeadMediaAssetUrl(image1FileName), 264, 264),
        mobile2: imageUrl(CMSLeadMediaAssetUrl(image2FileName), 264, 264),
        mobile3: imageUrl(CMSLeadMediaAssetUrl(image3FileName), 264, 264),
        tablet1: imageUrl(CMSLeadMediaAssetUrl(image1FileName), 336, 336),
        tablet2: imageUrl(CMSLeadMediaAssetUrl(image2FileName), 336, 336),
        tablet3: imageUrl(CMSLeadMediaAssetUrl(image3FileName), 336, 336),
    };

    const image1Props = {
        src: imageUrls.tablet1,
        srcSet: `${imageUrls.mobile1} 264w, ${imageUrls.tablet1} 336w`,
        width: 264,
        height: 336
    };
    const image2Props = {
        src: imageUrls.tablet2,
        srcSet: `${imageUrls.mobile2} 264w, ${imageUrls.tablet2} 336w`,
        width: 264,
        height: 336
    };
    const image3Props = {
        src: imageUrls.tablet3,
        srcSet: `${imageUrls.mobile3} 264w, ${imageUrls.tablet3} 336w`,
        width: 264,
        height: 336
    };

    /* eslint-disable max-len */
    return (
        <div className={styles.companyContainer}>
            <AnimationContainer>
                <Text className={styles.companyContainer__h1} textStyle="heading-1" htmlTag="h2">
                    A smarter way to search for jobs
                </Text>
            </AnimationContainer>
            <div className={styles.companyCulture_1}>
                <img loading="lazy" className={styles.companyCulture_1__image} alt="Company Culture 1" {...image1Props} />
                <Text
                    className={styles.companyCulture_1__h1}
                    textStyle="heading-1"
                    htmlTag="h3"
                >
                    What we offer job seekers
                </Text>
                <Text
                    className={styles.companyCulture_1__subtext}
                    textStyle="sans-serif-body"
                    htmlTag="p"
                >
                    We unlock professional advice for people exploring their options, job searches tailored to personal
                    priorities, company profiles that go behind the scenes at an org, and more.
                </Text>
            </div>
            <div className={styles.companyCulture_2}>
                <img loading="lazy" className={styles.companyCulture_2__image} alt="Company Culture 2" {...image2Props} />
                <div className={styles.companyCulture_2_overflow}>
                    <Text className={styles.companyCulture_2_overflow__h1} textStyle="heading-1" htmlTag="h3">
                        What we offer employers
                    </Text>
                    <Text
                        className={styles.companyCulture_2_overflow__subtext}
                        textStyle="sans-serif-body"
                        htmlTag="p"
                    >
                        We help companies build a more robust, informed candidate pipeline and strengthen their employer
                        brand through authentic storytelling and targeted job postings. You can even earn special
                        recognition for your perks and benefits through our annual
                        {" "}
                        <a href="https://www.themuse.com/advice/vibe-awards-2023">VIBE Awards</a>
                        .
                    </Text>
                </div>
            </div>
            <div className={styles.companyCulture_3}>
                <img loading="lazy" className={styles.companyCulture_3__image} alt="Company Culture 3" {...image3Props} />
                <div className={styles.companyCulture_3_overflow}>
                    <Text className={styles.companyCulture_3_overflow__h1} textStyle="heading-1" htmlTag="h3">
                        How we do it better
                    </Text>
                    <Text
                        className={styles.companyCulture_3_overflow__subtext}
                        textStyle="sans-serif-body"
                        htmlTag="p"
                    >
                        We combine the speed and scale of technology with a deep understanding of people to clarify
                        your career search and connect you with companies that fit your life and goals.
                    </Text>
                </div>
            </div>
        </div>
    );
};
Culture.displayName = "Culture";

export { Culture };
