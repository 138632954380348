import React from "react";

import styles from "./CoachCard.module.scss";

interface CoachCardProps {
    imageUrl: string;
    title: string;
    coachLevel: string;
    details: string;
    link: string;
}

const CoachCard = (props: CoachCardProps) => {
    const {
        link, imageUrl, title, coachLevel, details
    } = props;
    const height = 80;
    const width = 80;
    const specialties = `Specialties: ${details}`;

    return (
        <section className={styles.coachCard}>
            <a href={link}>
                <img
                    alt={`${title}`}
                    className={styles.coachCardImage}
                    height={height}
                    loading="lazy"
                    src={imageUrl}
                    width={width}
                />
                <h3 className={styles.coachCardTitle}>
                    {title}
                </h3>
                <p className={styles.coachCardLevel}>
                    {coachLevel}
                </p>
                <p className={styles.coachCardDetails}>
                    {specialties}
                </p>
            </a>
        </section>
    );
};

export { CoachCard };
export type { CoachCardProps };
