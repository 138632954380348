/* eslint-disable max-len */
import React from "react";

import { SquareButton, Text } from "@themuse/design-system";
import { CoachCard, CoachCardProps } from "./CoachCard";
import { AnimationContainer } from "./AnimationContainer";

import styles from "./CoachContent.module.scss";

const pilboxUrl = "https://pilbox.themuse.com/image.jpg"
                + "?filter=antialias&h=80&opt=1&pos=top-left&prog=1&q=keep&w=80&url=";
const coachUrl = "https%3A%2F%2Fassets.themuse.com%2Fuploaded%2Fuser%2F";

const coachData = [
    {
        imageUrl: `${pilboxUrl}${coachUrl}3398751%2Fcoach.jpg`,
        title: "Erica Walls",
        coachLevel: "Mentor",
        details: "College/New Grads, New Managers, Mid Career, Women, Career Changers, Public Relations, Entrepreneurs, Education, Healthcare, Non-Profit, Confidence Coaching, Marketing, Consulting, Government, Client services, Returning To Workforce",
        link: "/coaches/erica-walls"
    },
    {
        imageUrl: `${pilboxUrl}${coachUrl}727132%2Fcoach.jpg`,
        title: "Yolanda M. Owens",
        coachLevel: "Mentor",
        details: "College/New Grads, Mid Career, Career Changers, Digital Marketing, Relocating, Education, Non-Profit, Media, Confidence Coaching, International Job Seekers, Start Ups, Human Resources/Recruiting, Creative/Music/Arts, Marketing",
        link: "/coaches/yolanda-m-owens"
    },
    {
        imageUrl: `${pilboxUrl}${coachUrl}556277%2Fcoach.jpg`,
        title: "Emily Liou, PHR, ELI-MP, CPC",
        coachLevel: "Professional",
        details: "College/New Grads, Mid Career, Women, Career Changers, Public Relations, Entrepreneurs, Digital Marketing, Relocating, Healthcare, Remote Job Seekers, Non-Profit, Finance, Media, Confidence Coaching, Fashion, International Job Seekers, Visa/Sponsorship, Account Management, Beauty, Engineering, Fitness/Health, Sales, Biotechnology, Start Ups, Human Resources/Recruiting, Creative/Music/Arts, Marketing, Consulting, Law, Government, Entertainment & Gaming, Tech, Manufacturing, Advertisement and Agencies, Client services, Real Estate & Construction, Insurance, Returning To Workforce, Hospitality",
        link: "/coaches/emily-liou"
    },
    {
        imageUrl: `${pilboxUrl}${coachUrl}410247%2Fcoach.jpg`,
        title: "Martin McGovern",
        coachLevel: "Professional",
        details: "College/New Grads, Mid Career, Women, Career Changers, Public Relations, Entrepreneurs, Digital Marketing, Data Science, Graphic Design, Education, Remote Job Seekers, Design, Finance, Media, Confidence Coaching, Fashion, Account Management, Engineering, Fitness/Health, Sales, Start Ups, Human Resources/Recruiting, Creative/Music/Arts, Marketing, Consulting, Entertainment & Gaming, Tech, Advertisement and Agencies, Client services, Returning To Workforce, Hospitality",
        link: "/coaches/martin-mcgovern-0db4a5"
    },
    {
        imageUrl: `${pilboxUrl}${coachUrl}3178788%2Fcoach.jpg`,
        title: "Pamela Heward",
        coachLevel: "Professional",
        details: "College/New Grads, Mid Career, Executives, Women, Veterans, Relocating, Data Science, Education, Healthcare, Remote Job Seekers, Non-Profit, Finance, Confidence Coaching, Fitness/ Health, Account Management, Engineering, Start Ups, Human Resources/Recruiting, Marketing, Consulting, Law, Entertainment & Gaming, Manufacturing, Client services, Real Estate & Construction, Insurance, Returning To Workforce, Hospitality",
        link: "/coaches/pamela-heward"
    }
];

const CoachContent = () => (
    <div className={styles.coachesContainer}>
        <AnimationContainer>
            <Text className={styles.darker} textStyle="heading-2" htmlTag="h2">
                Try Coach Connect
            </Text>
            <Text className={styles.darker} textStyle="sans-serif-body" htmlTag="p">
                Meet hundreds of certified coaches who are trained to help you improve your resume, create a
                strategy for your job search, and more.
            </Text>
        </AnimationContainer>
        <div className={styles.coachWrapper}>
            <div className={styles.coachContent}>
                {coachData.map((coach: CoachCardProps) => <CoachCard key={coach.imageUrl} {...coach} />)}
            </div>
            <div className={styles.fader_left} />
            <div className={styles.fader_right} />
        </div>
        <SquareButton
            className={styles.cta}
            color="secondary-100"
            href="/coaching"
            disabled={false}
            target={null}
        >
            Meet The Coaches
        </SquareButton>
    </div>
);

export { CoachContent, coachData };
