import React from "react";

import { Text, SquareButton } from "@themuse/design-system";
import { ArticleCard } from "./ArticleCard";
import { AnimationContainer } from "./AnimationContainer";

import styles from "./AdviceContent.module.scss";

const pilboxUrl = "https://pilbox.themuse.com/image.jpg";
const pilboxProps = "?filter=antialias&h=250&opt=1&pos=top-left&prog=1&q=keep&w=344&url=";
const landingUrl = "https%3A%2F%2Fcms-assets.themuse.com%2Fmedia%2Flead%2F";

const adviceTiles = [
    {
        title: "Succeeding at Work",
        imageUrl: `${pilboxUrl}${pilboxProps}${landingUrl}Succeeding_at_Work.jpg`,
        contentUrl: "/advice/succeeding-at-work",
        target: "Advice Succeeding at Work"
    },
    {
        title: "Career Paths",
        imageUrl: `${pilboxUrl}${pilboxProps}${landingUrl}Career_Paths.jpg`,
        contentUrl: "/advice/career-paths",
        target: "Advice Career Paths"
    },
    {
        title: "Job Search",
        imageUrl: `${pilboxUrl}${pilboxProps}${landingUrl}Job_Search.jpg`,
        contentUrl: "/advice/job-search",
        target: "Advice Job Search"
    }
];
const heading = "Forge a career that exceeds your ambitions.";

const AdviceContent = () => (
    <div className={styles.adviceContainer}>
        <div className={styles.adviceSpacer}>
            <AnimationContainer>
                <Text className={styles.h2} textStyle="heading-2" htmlTag="h2">
                    {heading}
                </Text>
                <Text className={styles.subheading} textStyle="sans-serif-body" htmlTag="p">
                    Get expert advice for every life stage and experience level.
                </Text>
            </AnimationContainer>
            <div className={styles.wrapper}>
                {adviceTiles.map((landing: any, index: number) => {
                    const {
                        target, title, imageUrl, contentUrl

                    } = landing;
                    const eventProps = {
                        placementOrder: index + 1,
                        linkHref: contentUrl,
                        linkName: title,
                        linkTarget: target,
                        heading
                    };
                    return (
                        <ArticleCard
                            key={contentUrl}
                            {...{
                                title, eventProps, imageUrl, contentUrl
                            }}
                        />
                    );
                })}
            </div>
            <SquareButton
                className={styles.cta}
                color="secondary-100"
                href="/advice"
            >
                Read More
            </SquareButton>
        </div>
    </div>
);

export { AdviceContent };
