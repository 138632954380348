import React from "react";
import getConfig from "next/config";
import { SquareButton, Text } from "@themuse/design-system";

import styles from "./CompanyLogos.module.scss";

const CMSLeadMediaAssetUrl = (urlPrefix: string, imageName: string) => {
    const imagePath = `/media/lead/${imageName}`;

    return `${urlPrefix}${imagePath}`;
};

const imageUrl = (pilboxUrl: string, assetUrl: string, width: number, height: number) => {
    const pilboxFilters = "&filter=antialias&opt=1&pos=center&prog=1&q=keep";
    const pilboxParams = `&url=${assetUrl}${width ? "&w=" : ""}${width || ""}&h=${height}${pilboxFilters}`;
    return `${pilboxUrl}?${pilboxParams}`;
};

const companyNames = [
    "TikTok",
    "Allbirds",
    "Nike",
    "Audible",
    "Goldman Sachs",
    "Robinhood"
];

const CompanyLogos = () => {
    const { publicRuntimeConfig: { staticAssetHost, pilboxHost } } = getConfig();
    const companyLogoUrls = [
        "homepage_tiktok_logo.png",
        "homepage_allbirds_logo.png",
        "homepage_nike_logo.png",
        "homepage_audible_logo.png",
        "homepage_goldman_sachs_logo.png",
        "homepage_robinhood_logo.png"
    ];
    const companyLogoImageProps = companyLogoUrls.map((logoImage) => {
        const logoImageUrl = imageUrl(pilboxHost, CMSLeadMediaAssetUrl(staticAssetHost, logoImage), 0, 51);
        return {

            src: logoImageUrl,
            height: "51px",
            width: "158px",
        };
    });

    return (
        <div className={styles.companyLogos}>
            <div className={styles.headingWrapper}>
                <Text className={styles.heading} textStyle="heading-1" htmlTag="h2">
                    Discover companies that share your values.
                </Text>
            </div>
            <div className={styles.display}>
                {companyLogoImageProps.map((logoImageProps, index) => {
                    const { src } = logoImageProps;
                    return (
                        <img
                            className={styles.logo}
                            key={src}
                            {...logoImageProps}
                            alt={companyNames[index]}
                            loading="lazy"
                        />
                    );
                })}
            </div>
            <SquareButton
                className={styles.companyCta}
                color="secondary-100"
                href="https://www.themuse.com/companies"
            >
                Browse Companies
            </SquareButton>
            <Text className={styles.companyHiringCopy} textStyle="sans-serif-body" htmlTag="p">
                Hiring?
                {" "}
                <a href="https://www.themuse.com/employers/#getstarted">Learn more</a>
                {" "}
                about featuring your brand on The Muse.
            </Text>
        </div>
    );
};

export { CompanyLogos };
