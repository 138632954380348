import { IndexedObject } from "@themuse/design-system";
import { NewsletterJobAlerts } from "components/NewsletterJobAlerts";

import styles from "./NewsLetter.module.scss";

export const jobAlertCategories: IndexedObject[] = [
    { label: "Media, PR, and Communications", value: "media_pr_communications" },
    { label: "Sales", value: "sales" },
    { label: "Product Management", value: "product_management" },
    { label: "Design and UX", value: "design_ux" },
    { label: "Software Engineering", value: "software_engineering" },
    { label: "Human Resources and Recruitment", value: "human_resources_recruitment" },
    { label: "Accounting and Finance", value: "accounting_finance" },
    { label: "Project Management", value: "project_management" },
    { label: "Customer Service", value: "customer_service" },
    { label: "Data and Analytics", value: "data_analytics" },
    { label: "Account Management", value: "account_management" },
    { label: "Administration and Office", value: "administration_office" },
    { label: "Advertising and Marketing", value: "advertising_marketing" },
    { label: "Animal Care", value: "animal_care" },
    { label: "Arts", value: "arts" },
    { label: "Business Operations", value: "business_operations" },
    { label: "Cleaning and Facilities", value: "cleaning_facilities" },
    { label: "Computer and IT", value: "computer_it" },
    { label: "Construction", value: "construction" },
    { label: "Education", value: "education" },
    { label: "Energy Generation and Mining", value: "energy_generation_mining" },
    { label: "Entertainment and Travel Services", value: "entertainment_travel" },
    { label: "Farming and Outdoors", value: "farming_outdoors" },
    { label: "Food and Hospitality Services", value: "food_hospitality" },
    { label: "Healthcare", value: "healthcare" },
    { label: "Installation, Maintenance, and Repairs", value: "installation_maintenance_repairs" },
    { label: "Legal Services", value: "legal" },
    { label: "Management", value: "management" },
    { label: "Manufacturing and Warehouse", value: "manufacturing_warehouse" },
    { label: "Personal Care and Services", value: "personal_care" },
    { label: "Protective Services", value: "protective_services" },
    { label: "Real Estate", value: "real_estate" },
    { label: "Retail", value: "retail" },
    { label: "Science and Engineering", value: "science_engineering" },
    { label: "Social Services", value: "social_services" },
    { label: "Sports, Fitness, and Recreation", value: "sports_fitness_recreation" },
    { label: "Transportation and Logistics", value: "transportation_logistics" },
    { label: "Writing and Editing", value: "writing_editing" },
];

export const locationAltValues: Record<string, string> = {
    "Remote / Flexible": "Flexible / Remote",
};

export const trackingData = {
    newsletter: {
        impression: JSON.stringify({
            sp_impression_component: "insert",
            sp_impression_modal_type: "newsletter",
            sp_impression_position: "main",
            sp_page_company_id: null,
            sp_page_is_external: false,
            sp_page_section: "homepage",
            sp_page_tab: null,
            sp_page_type: "other",
        }),
        success: {
            sp_modal_type: "newsletter",
            sp_modal_error_type: null,
            sp_modal_action_type: "submit",
            sp_modal_newsletter_opt_in: null,
            sp_modal_screen_order: null,
            sp_modal_screen_name: null,
        },
    },
    signup: {
        impression: JSON.stringify({
            sp_impression_component: "insert",
            sp_impression_modal_type: "job_alert",
            sp_impression_position: "main",
            sp_page_company_id: null,
            sp_page_is_external: false,
            sp_page_section: "homepage",
            sp_page_tab: null,
            sp_page_type: "other",
        }),
        notice: JSON.stringify({
            sp_impression_component: "insert",
            sp_impression_modal_type: "newsletter_success",
            sp_impression_position: "main",
            sp_page_company_id: null,
            sp_page_is_external: false,
            sp_page_section: "homepage",
            sp_page_tab: null,
            sp_page_type: "other",
        }),
        success: {
            sp_modal_type: "job_alert",
            sp_modal_error_type: null,
            sp_modal_action_type: "submit",
            sp_modal_newsletter_opt_in: null,
            sp_modal_job_alert_opt_in: true,
            sp_modal_screen_order: null,
            sp_modal_screen_name: null,
        },
    },
    success: {
        impression: JSON.stringify({
            sp_impression_component: "insert",
            sp_impression_modal_type: "jobalert_success",
            sp_impression_position: "main",
            sp_page_company_id: null,
            sp_page_is_external: false,
            sp_page_section: "homepage",
            sp_page_tab: null,
            sp_page_type: "other",
        }),
    },
};

const Newsletter = () => (
    <NewsletterJobAlerts className={styles.newsJobAlerts} {...{ trackingData }} />
);

Newsletter.displayName = "Newsletter";
export { Newsletter };
