/* eslint-disable max-len */
import React from "react";

import { AnimationContainer } from "./AnimationContainer";
import { SearchDropdown } from "./SearchDropdown";

import styles from "./HomepageHeader.module.scss";

const HomepageHeader = () => (
    <div className={styles.headerContainer}>
        <div className={styles.left}>
            <div>
                <AnimationContainer>
                    <h1 className={styles.h1}>
                        Find work
                        <br />
                        {" "}
                        that&apos;s
                        {" "}
                        <span>worth it.</span>
                    </h1>
                    <p className={styles.subtext}>
                        Make a career match where the people, perks and values align with your needs.
                    </p>
                </AnimationContainer>
                <SearchDropdown />
            </div>
        </div>
        <div className={styles.right}>
            <div className={styles.images}>
                <div className={styles.stars} />
            </div>
        </div>
    </div>
);

export { HomepageHeader };
