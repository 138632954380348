import React, {
    FC, useCallback, useEffect, useRef, useState
} from "react";
import { useInView } from "react-intersection-observer";
import { UseComboboxStateChange } from "downshift";
import { IndexedObject, NewsletterAlertsCard, NewsletterAlertsCardProps } from "@themuse/design-system";
import { triggerSnowplowImpressionEvent, triggerSnowplowModalActionEvent } from "@themuse/snowplow-js-client";
import mockLocations from "mocks/mockLocations.json";
import {
    fetchLocations, jobAlertsSubscribeRequest, newsletterSubscribeRequest, NewsletterSubmissionValues
} from "utils/api";
import { jobAlertCategories, locationAltValues } from "./newsletterJobAlerts.constants";
import styles from "./NewsletterJobAlerts.module.scss";

const dropdownClasses = {
    containerOpened: styles.dropdown_containerOpened,
    menuOpened: styles.dropdown_menuOpened,
};

const filterList = (value = "") => jobAlertCategories.filter(
    (loc: IndexedObject) => loc.value.toLowerCase().includes(value.toLowerCase())
);

interface TrackingData {
    newsletter: {
        impression: string,
        success: any,
    },
    signup: {
        impression: string,
        notice: string,
        success: any,
    },
    success: {
        impression: string,
    },
}

export type NewsletterJobAlertsProps = {
    trackingData?: TrackingData;
} & Partial<NewsletterAlertsCardProps>

export const NewsletterJobAlerts: FC<NewsletterJobAlertsProps> = (props: NewsletterJobAlertsProps) => {
    const [email, setEmail] = useState<string>("");
    const [categories, setCategories] = useState<IndexedObject[]>(jobAlertCategories);
    const [locations, setLocations] = useState<IndexedObject[]>(mockLocations);
    const newsletterView = useInView({ triggerOnce: true, rootMargin: "0px" });
    const elementsViewed = useRef<Record<string, boolean>>({});
    const { trackingData, ...restProps } = props;

    const handleNewsletterSubmit = (values: NewsletterSubmissionValues) => {
        setEmail(values.email);
        return newsletterSubscribeRequest(values)
            .then(() => true)
            .catch((e) => {
                console.error(e);
                return false;
            });
    };

    const handleRefViewed = (el: HTMLElement, key: string) => {
        if (!elementsViewed.current[key]) {
            elementsViewed.current[key] = true;
            triggerSnowplowImpressionEvent(el);
        }
    };

    const handleCategoryInput = ({ inputValue }: UseComboboxStateChange<IndexedObject>) => {
        if (!inputValue) {
            setCategories(jobAlertCategories);
            return;
        }
        if (categories.find(({ label }) => label === inputValue)) {
            return;
        }
        setCategories(
            filterList(inputValue)
        );
    };

    const handleLocationInput = ({ inputValue }: UseComboboxStateChange<IndexedObject>) => {
        if (!inputValue) {
            setLocations(mockLocations);
            return;
        }
        if (locations.find(({ label }) => label === inputValue)) {
            return;
        }
        fetchLocations(inputValue).then((result) => setLocations(result || []));
    };

    const handleJobAlertsSubmit: NewsletterAlertsCardProps["jobAlertsProps"]["onSubmit"] = useCallback(async (formData) => {
        const apiCategory = jobAlertCategories.find(({ label }) => label === formData.category)?.value || formData.category;
        const apiLocation = locationAltValues[formData.location] || formData.location;
        const trackingSuccess = {
            ...trackingData.signup.success,
            sp_modal_job_category: apiCategory,
            sp_modal_job_location: formData.location,
        };

        try {
            const response = await jobAlertsSubscribeRequest({
                category: apiCategory,
                email,
                keyword: formData.category,
                location: apiLocation,
                newsletter: true,
            });
            triggerSnowplowModalActionEvent({
                ...trackingSuccess,
                sp_modal_error_type: response ? null : "invalid_email",
            });
            return !!response;
        } catch (e) {
            console.error(e);
            triggerSnowplowModalActionEvent({
                ...trackingSuccess,
                sp_modal_error_type: "invalid_email",
            });
            return false;
        }
    }, [email, locations]);

    useEffect(() => {
        if (newsletterView.inView && !elementsViewed.current.newsletter) {
            const el = newsletterView.entry.target as HTMLDivElement;
            el.dataset.trackingProps = trackingData.newsletter.impression;
            triggerSnowplowImpressionEvent(el as HTMLElement);
            elementsViewed.current.newsletter = true;
        }
    }, [newsletterView.inView]);

    return (
        <NewsletterAlertsCard
            jobAlertsProps={{
                // @ts-ignore
                "data-tracking-props": trackingData.signup.impression,
                inputCategoryProps: {
                    classes: dropdownClasses,
                    dropdownProps: {
                        dropdownList: categories,
                        dropdownTitle: "Type to Select Category",
                        inputId: "jobAlertsSignupCategory",
                        labelText: "",
                        onInputChange: handleCategoryInput,
                    },
                },
                inputLocationProps: {
                    classes: dropdownClasses,
                    dropdownProps: {
                        debounceWait: 200,
                        dropdownList: locations,
                        dropdownTitle: "Type to Select a Location",
                        inputId: "jobAlertsSignupLocation",
                        labelText: "",
                        onInputChange: handleLocationInput,
                    },
                },
                notice: (
                    <span
                        data-tracking-props={trackingData.signup.notice}
                        ref={(el) => handleRefViewed(el, "jobAlertsNotice")}
                    >
                        <strong>You are all set!</strong>
                        {" "}
                        You&rsquo;ll be receiving weekly
                        newsletters to inspire you during your job search journey.
                    </span>
                ),
                onSubmit: handleJobAlertsSubmit,
                wrapperRef: (el) => handleRefViewed(el, "jobAlerts"),
            }}
            newsletterProps={{
                formRef: newsletterView.ref,
                onFormError: () => triggerSnowplowModalActionEvent({
                    ...trackingData.newsletter.success,
                    sp_modal_error_type: "Job Alerts subscription was unsuccessful.",
                }),
                onFormSubmit: handleNewsletterSubmit,
                onFormSuccess: () => triggerSnowplowModalActionEvent(trackingData.newsletter.success),
            }}
            successProps={{
                // @ts-ignore
                "data-tracking-props": trackingData.success.impression,
                wrapperRef: (el) => handleRefViewed(el, "success"),
            }}
            {...restProps}
        />
    );
};
