import React from "react";
import { triggerSnowplowClickEvent } from "@themuse/snowplow-js-client";
import getConfig from "next/config";

import styles from "./RecentArticles.module.scss";

interface ITaxonomy {
    id: number;
    title: string;
    slug: string;
    parent?: {
        title: string;
        slug: string;
        uri: string;
    };
}

export type IArticleTile = {
    uri: string;
    writer: {
        uri: string;
        writerName: string;
    };
    leadMediaPhoto: [
        {
            "id": number
            "url": string;
            "width": 750,
            "height": 367,
            "altText": string;
            "caption": string;
            "credit": string;
            "creditLink": null
        }
    ];
    primaryTopic: ITaxonomy;
    companySponsor?: {
        id: number;
    };
    title: string;
    id?: string;
    dateCreated?: string;
    dateUpdated?: string;
}

// Generated the tracking props for each tile.
const getTrackingProps = (article: IArticleTile, index: number) => {
    const { id, companySponsor } = article;
    const props: Record<string, string | number | null> = {
        // SnowplowClickEventProps
        sp_click_button_text: null,
        sp_click_button_type: null,
        sp_click_component: "tile",
        sp_click_component_tile_type: "article",
        sp_click_insert_type: null,
        sp_click_link_text: article.title,
        sp_click_position: "bottom",
        sp_click_search_id: null,
        sp_click_target_page_section: "advice",
        // SnowplowTileContentProps
        sp_tile_company_id: companySponsor?.id?.toString() ?? "",
        sp_tile_entity_id: id.toString() ?? "",
        sp_tile_entity_uuid: "",
        sp_tile_location: "recent_articles",
        sp_tile_order: index,
        sp_tile_type: "article",
        // SnowplowImpressionProps
        sp_impression_button_type: null,
        sp_impression_component: "tile",
        sp_impression_insert_type: null,
        sp_impression_modal_type: null,
        sp_impression_position: "bottom",
        sp_impression_search_id: null,
        sp_impression_tile_type: "article",
    };
    return props;
};

// Generates an individual tile.
const ArticleTile = (props: { article: IArticleTile, index: number }) => {
    const { publicRuntimeConfig: { pilboxHost, staticAssetHost } } = getConfig();
    const { article, index } = props;
    const {
        companySponsor,
        id,
        leadMediaPhoto,
        title,
        uri,
        writer,
    } = article;
    const { writerName } = writer;

    // alt text could be null and lead media might not exist
    const altText = leadMediaPhoto?.[0]?.altText ?? "Not found";
    // url text could be null and lead media might not exist
    const url = leadMediaPhoto?.[0]?.url ?? `${staticAssetHost}/media/lead/placeholder.png`;

    const tileTrackingProps: Record<string, string | number | null> = getTrackingProps(article, index);
    return (
        <article
            className={styles.RecentTile}
            data-tracking-props={JSON.stringify(tileTrackingProps)}
            data-tracking-event-type="impression"
        >
            <a
                className={styles.linkWrapper}
                href={`/${uri}`}
                onClick={() => triggerSnowplowClickEvent({
                    // SnowplowClickEventProps
                    sp_click_button_text: null,
                    sp_click_button_type: null,
                    sp_click_component: "tile",
                    sp_click_component_tile_type: "article",
                    sp_click_insert_type: null,
                    sp_click_link_text: article.title,
                    sp_click_position: "bottom",
                    sp_click_search_id: null,
                    sp_click_target_page_section: "advice",
                    // SnowplowTileContentProps
                    sp_tile_company_id: companySponsor?.id?.toString() ?? "",
                    sp_tile_entity_id: id.toString() ?? "",
                    sp_tile_entity_uuid: "",
                    sp_tile_location: "recent_articles",
                    sp_tile_order: index,
                    sp_tile_type: "article",
                })}
            >
                <img
                    className={styles.image}
                    height="111"
                    width="111"
                    alt={altText}
                    loading="lazy"
                    src={`${pilboxHost}/image.jpg?filter=antialias&opt=1&mode=crop&pos=center&prog=1&q=keep&url=${url}&w=111&h=111`}
                />
                <div className={styles.content}>
                    <p className={styles.author}>{writerName}</p>
                    <h3 className={styles.title}>{title}</h3>
                </div>
            </a>
        </article>
    );
};

// The main component with all tiles.
const RecentArticles = (props: { recentArticles: IArticleTile[]; }) => {
    const { recentArticles } = props;

    if (!recentArticles?.length) {
        return (<aside data-msg="no data" />);
    }

    return (
        <aside className={styles.RecentArticles}>
            <h2 className={styles.RecentArticlesHeading}>Check out recent articles.</h2>
            <p className={styles.RecentArticlesSubHeading}>Get all the information and support you need to find work that&apos;s worth it.</p>
            {recentArticles.map((article: IArticleTile, index: number) => (
                <ArticleTile
                    key={article.uri}
                    article={article}
                    index={index + 1}
                />
            ))}
        </aside>
    );
};

export { RecentArticles };
