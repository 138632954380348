import React, { useState } from "react";
import getConfig from "next/config";
import { SquareButton } from "@themuse/design-system";
import { triggerSnowplowClickEvent } from "@themuse/snowplow-js-client";
import { FeaturedCompany } from "interfaces/featuredCompanies";
import styles from "./CompanyCard.module.scss";

/**
 * Renders a company card component.
 */
const CompanyCard = (props: {
    company: FeaturedCompany, index: number; featureHeading: string;
}) => {
    const { index, company, featureHeading } = props;
    const {
        shortName = "",
        description = "",
        primaryImage = "",
        title = "",
        slug = "",
        legacyId = "0",
        id = 0,
        logoImage = ""
    } = company;
    const { publicRuntimeConfig: { staticAssetHost, pilboxHost, museHost } } = getConfig();

    const eventProps = {
        content_type: "module",
        company_name: title,
        company_short_name: slug,
        company_id: legacyId?.toString() ?? null,
        company_craft_id: id,
        placement_position: index,
        sp_content_is_tile: true,
        sp_tile_entity_id: legacyId?.toString() ?? null,
        sp_tile_order: index,
        sp_tile_type: "profile",
        sp_tile_company_id: legacyId?.toString() ?? null,
        sp_impression_component: "tile",
        sp_impression_position: "main",
        sp_impression_tile_type: "profile",
        sp_page_section: "homepage",
        sp_click_component: "tile",
        sp_click_button_type: null,
        sp_click_component_tile_type: "profile",
        sp_click_button_text: null,
        sp_click_position: "main",
        sp_click_target_page_section: "profile",
        sp_tile_location: featureHeading
    };
    const trackingProps = JSON.stringify(eventProps);

    const heroWidth = {
        xs: 327,
        sm: 336,
        md: 288,
        lg: 376
    };

    const profileUrl = `${museHost}/profiles/${shortName}`;
    const jobsUrl = `${museHost}/search/company/${shortName}`;
    const pilboxPath = `${pilboxHost}/image.jpg?filter=antialias&opt=1&pos=top-left&prog=1&q=keep&url=`;

    const backupHero = "https://assets.themuse.com/images/missing-company-hero.jpg";
    const [heroSrc, setHeroSrc] = useState<string>(primaryImage ? `${staticAssetHost}/${primaryImage}` : backupHero);
    const heroOnError = () => setHeroSrc(backupHero);

    // eslint-disable-next-line max-len
    const logoBackupSrc = "data:image/svg+xml,<svg height='37' width='29' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 29 37' ><g stroke='%23767676'><path d='M28.5 35.028a1 1 0 0 1-1 1h-13V1.972a1 1 0 0 1 1.582-.814l12 8.572a1 1 0 0 1 .418.814v24.484ZM26.5 8.6V3.028m-12 7h4m-4 6h6m-20 6h6m-6 6h6m8-6h9'></path><path d='M18.5 36.028v-7a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v7m-10-20h-13a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h13'></path></g></svg>";
    const [logoSrc, setLogoSrc] = useState<string>(logoImage ? `${staticAssetHost}/${logoImage}` : logoBackupSrc);
    const logoOnError = () => setLogoSrc(logoBackupSrc);

    /**
     * Returns a callback function for a button click event.
     */
    const getButtonCallBack = (btnProps: {
        type: string, text: string, target: string;
    }): Function => (event: React.MouseEvent<HTMLAnchorElement>): boolean => {
        const { type, text, target } = btnProps;
        event.preventDefault();
        triggerSnowplowClickEvent({
            ...eventProps,
            sp_click_component: "tile",
            sp_click_insert_type: null,
            sp_click_button_type: type,
            sp_click_link_text: null,
            sp_click_button_text: text,
            sp_click_target_page_section: target,
            sp_click_search_id: null
        });
        window.location.href = target === "profile" ? profileUrl : jobsUrl;
        return false;
    };

    /**
     * Returns a callback function for a button click event.
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getImageCallBack = (): Function => (_: React.MouseEvent<HTMLAnchorElement>): void => {
        triggerSnowplowClickEvent({
            ...eventProps,
            sp_click_component: "tile",
            sp_click_insert_type: null,
            sp_click_button_type: "profile",
            sp_click_link_text: "<image>",
            sp_click_button_text: null,
            sp_click_target_page_section: "profile",
            sp_click_search_id: null
        });
        window.location.href = profileUrl;
    };

    const profileBtnCB = getButtonCallBack({ type: "profile", text: "VIEW PROFILE", target: "profile" });
    const searchBtnCB = getButtonCallBack({ type: "job_search", text: "VIEW JOBS", target: "search" });
    const imageCB = getImageCallBack();

    return (
        <article
            className={styles.companyCard}
            data-tracking-props={trackingProps}
            data-tracking-event-type="impression"
        >
            <div className={styles.header} aria-hidden="true" onClick={() => imageCB()}>
                <img
                    alt=""
                    className={styles.hero}
                    src={`${pilboxPath}${heroSrc}&w=${heroWidth.xs}`}
                    srcSet={`
                        ${pilboxPath}${heroSrc}&w=${heroWidth.xs} ${heroWidth.xs}w,
                        ${pilboxPath}${heroSrc}&w=${heroWidth.sm} ${heroWidth.sm}w,
                        ${pilboxPath}${heroSrc}&w=${heroWidth.md} ${heroWidth.md}w,
                        ${pilboxPath}${heroSrc}&w=${heroWidth.lg} ${heroWidth.lg}w
                    `}
                    loading="lazy"
                    sizes={`(max-width: ${heroWidth.lg}px) ${heroWidth.xs}px, ${heroWidth.sm}px, ${heroWidth.md}px, ${heroWidth.lg}px`}
                    width="327"
                    height="218"
                    data-tracking-event-type="click"
                    onError={heroOnError}
                />
                <img
                    className={styles.logo}
                    src={logoSrc}
                    width="80"
                    height="80"
                    alt=""
                    onError={logoOnError}
                />
            </div>
            <div className={styles.content}>
                <h3 className={styles.name}>{title ?? ""}</h3>
                <p className={styles.description}>{description?.replace(/<[^>]*>/g, "") ?? ""}</p>
                <footer className={styles.footer}>
                    <SquareButton
                        className={styles.search}
                        color="white"
                        href={jobsUrl}
                        onClick={(e) => searchBtnCB(e)}
                    >
                        view jobs
                    </SquareButton>

                    <SquareButton
                        className={styles.profile}
                        color="secondary-100"
                        href={profileUrl}
                        onClick={(e) => profileBtnCB(e)}
                    >
                        view profile
                    </SquareButton>
                </footer>
            </div>
        </article>
    );
};

export { CompanyCard };
